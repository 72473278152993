body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Workaround for [PHLB-44]
 * Reason: MuiDataTables doesn't allow styling override for checkbox in columnsFilter.
 * Same behavior exists for days selected and current into calendar component.
 * The main color used by MuiDataTable on checked checkbox is the main palette color defined into the global theme. 
 */
span[data-description="table-view-col"].Mui-checked {
  color: #3A4E5F;
}

.MuiPickersDay-daySelected {
  background-color: #3A4E5F !important;
}