* {
  text-decoration: none;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* .MuiPaper-root.MuiPopover-paper.MuiPaper-elevation2.MuiPaper-rounded ,
  .MuiPaper-root.MuiPopover-paper.MuiPaper-elevation2.MuiPaper-rounded > div {
  background-color: #101010 !important;
  color: #dfdbd9 !important;
}

.MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input.MuiInput-input {
  color: #dfdbd9 !important;
}

#mui-component-select-shoot_type {
  border-color: #dfdbd9 !important;
} */

.jss969 {
    align-items: center;
}

/* custom scrollbar */

* {
    scrollbar-color: #515151 #aaaaaa;
    scrollbar-width: thin;
}

*::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

*::-webkit-scrollbar-track {
    background: #515151;
}

*::-webkit-scrollbar-thumb {
    background: #aaaaaa !important;
    border-radius: 0 !important;
    border: none !important;
}

*::-webkit-scrollbar-thumb:hover {
    background: #344755;
}
